<template>
    <el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-dropdown @command="onSelectType">
                <el-button type="primary" plain style="width:144px;">
                    {{curTypeLabel}}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in feedbackTypes" :key="item.id" :command="item" icon="el-icon-help">
                        {{item.name}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-select v-model="curGroup" value-key="id" style="width:180px; margin-left: 8px;" @change="onSelectGroup" placeholder="选择区服列表">
                <el-option v-for="item in serverGroups" :key="item.id" :label="item.name" :value="item">
                </el-option>
            </el-select>                
            <el-select v-model="curServerid" value-key="id" style="width:180px; margin-left: 8px;" @change="onSelectServer" placeholder="区服">
                <el-option v-for="item in serverItems" :key="item.id" :label="item.title" :value="item.id">
                </el-option>
            </el-select>
            <el-date-picker type="daterange" style="margin-left: 8px;" :editable="false" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" v-model="queryDate" @change="onSelectDate">
            </el-date-picker>
        </el-col>
        <el-col :span="24">
            <el-table :data="feedbackList" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
                :cell-style="{padding:8+'px'}" class="serverlist">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                      <el-form-item v-for="item in labelArr" :key="item.prop" :label="item.label" style="width: 100%;" label-width="64px">
                        <span>{{ props.row[item.prop] }}</span>
                      </el-form-item>
                    </el-form>
                  </template>
                </el-table-column>
                <el-table-column v-for="item in labelArr" :key="item.prop" :prop="item.prop" :label="item.label" show-overflow-tooltip></el-table-column>
                <el-table-column label="类型" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{feedbackTypeName(scope.row.ftype)}}</span>
                </template>
                </el-table-column>            
            </el-table>
            <el-pagination
              @current-change="handleCurrentChange"
              layout="total, prev, pager, next"
              background
              :page-size="20"
              :total="total">
            </el-pagination>
        </el-col>
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    import tool from '../../tool.js'

    export default {
        data() {
            return {
                curTypeId: 0,
                curTypeLabel: '全部类型',
                feedbackTypes: this.$store.state.game.feedbackTypes,
                labelArr: [{prop:'id',label:'id'},{prop:'create_time',label:'时间'},{prop:'area',label:'区服'},{prop:'cid',label:'玩家ID'}],
                feedbackList: [],
                curPage: 1,
                total: 0,
                curGroup: '',
                curServerid: '',
                serverGroups: [],
                serverItems: [],
                queryDate: [], // 查询日期范围
                tableHeight: document.documentElement.clientHeight-136-32, 
            }
        },
        created: function() {
            this.refreshGroupList();
            this.refreshFeedbackList();
            // 表头设定
            let fieldarr = this.$store.state.game.feedback;
            for (let i=0; i<fieldarr.length; i++) {
                this.labelArr.push( fieldarr[i] );
            }
        },
        computed: {
            ...mapGetters(
                ['gameHttp']
            ),
        },
        methods: {
            // 选择反馈类型
            onSelectType(item) {
                this.curTypeLabel = item.name
                this.curTypeId = item.id
                // 刷新选择结果
                this.refreshFeedbackList();
            },     
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {
                    plain: 1
                }).then((response) => {
                    this.serverGroups = response.data.list
                    this.serverGroups.splice(0, 0, {
                        id: -1,
                        name: "全部区服列表"
                    })
                })
            },
            // 选择分组
            onSelectGroup(item) {
                this.curServerid = 0;
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: item.id,
                }).then((response) => {
                    this.serverItems = response.data.list;
                    this.serverItems.splice(0, 0, {
                        id: 0,
                        title: "全部区服"
                    });
                });
                this.refreshFeedbackList();
            },
            // 选择区服
            onSelectServer() {
                this.refreshFeedbackList();
            },
            // 选择日期
            onSelectDate() {
                if (this.queryDate) {
                    this.refreshFeedbackList();
                }
            },
            // 查询列表
            refreshFeedbackList() {
                let rqs = {
                    type:this.curTypeId,
                    group: this.curGroup?this.curGroup.id:-1,
                    server: this.curServerid?this.curServerid:0,
                    page:this.curPage,
                };
                if (this.queryDate && this.queryDate.length) {
                    rqs.dfrom = tool.dateFormatD(this.queryDate[0]);
                    rqs.dto = tool.dateFormatD(this.queryDate[1]);
                }                
                this.gameHttp(3051, rqs).then((response) => {
                    this.dialogForm = false;
                    if (0 == response.data.errcode) {
                        this.total = response.data.total;
                        this.feedbackList = response.data.list;
                    }
                })
            },
            // 分页查看
            handleCurrentChange(page) {
                this.curPage = page;
                this.refreshFeedbackList();
            },
            // 反馈类型的名称
            feedbackTypeName(id) {
                for (let i=0; i<this.feedbackTypes.length; i++) {
                    if (id == this.feedbackTypes[i].id) {
                        return this.feedbackTypes[i].name;
                    }
                }
                return 'notype';
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                rowIndex
            }) {
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
        },
    }
</script>

<style>
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }

    .serverlist.el-table {
        margin-top: 16px;
    }

    .serverlist {
        font-size: 12px;
    }
    
    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }
</style>
